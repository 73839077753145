import { SerializationDateUtilsDeprecated } from '../deprecated/serialization';
import { WEEK_DAY_ABBREVIATION_FORMAT } from '../formats';
import { SupportedDateType } from '../supportedDateType';

export class SerializationDateUtils {
  public static getDateFromServerString(value: string): Date {
    return SerializationDateUtilsDeprecated.getMomentFromServerString(
      value
    ).toDate();
  }

  public static getDateTimeFromServerString(value: string): Date {
    return SerializationDateUtilsDeprecated.getMomentWithTimeFromServerString(
      value
    ).toDate();
  }

  public static formatDateForApi(
    date: SupportedDateType | undefined,
    sourceFormat?: string
  ): string {
    return SerializationDateUtilsDeprecated.formatDateForApi(
      date,
      sourceFormat
    );
  }

  public static formatTimeForApi(date: SupportedDateType | undefined): string {
    return SerializationDateUtilsDeprecated.formatTimeForApi(date);
  }

  public static formatDateTimeForApi(
    date: SupportedDateType | undefined
  ): string {
    return SerializationDateUtilsDeprecated.formatDateTimeForApi(date);
  }

  public static formatDateTimeForDisplay(
    date?: SupportedDateType,
    inputFormat?: string,
    outputFormat?: string
  ): string {
    return SerializationDateUtilsDeprecated.formatDateTimeForDisplay(
      date,
      inputFormat,
      outputFormat
    );
  }

  public static format(
    date: SupportedDateType | undefined,
    format: string
  ): string {
    return SerializationDateUtilsDeprecated.format(date, format);
  }

  public static formatWithWeekDayAbbr(
    date: SupportedDateType | undefined,
    format: string
  ): string {
    return SerializationDateUtilsDeprecated.format(
      date,
      `${format} ${WEEK_DAY_ABBREVIATION_FORMAT}`
    );
  }

  public static formatTimeTo24 = (timeString: string) => {
    return SerializationDateUtilsDeprecated.formatTimeTo24(timeString);
  };

  public static formatTimeTo12 = (timeString: string): string => {
    return SerializationDateUtilsDeprecated.formatTimeTo12(timeString);
  };
}
