import { SEPInternalFrontendUrlKeyType } from 'src/declarations/config';

export const BROWSER_STORAGE = {
  LocalStorage: 'localStorage',
  SessionStorage: 'sessionStorage',
};

type BROWSER_STORAGE_TYPE =
  (typeof BROWSER_STORAGE)[keyof typeof BROWSER_STORAGE];

export class BrowserStorage {
  private storage: BROWSER_STORAGE_TYPE;

  constructor(storageType: BROWSER_STORAGE_TYPE) {
    this.storage = storageType;
  }

  get(key: string, microservice: SEPInternalFrontendUrlKeyType): string | null {
    return window[this.storage].getItem(`${microservice}.${key}`);
  }

  getParsed<T>(
    key: string,
    microservice: SEPInternalFrontendUrlKeyType
  ): T | null {
    const item = this.get(key, microservice);
    return JSON.parse(item || 'null') as T | null;
  }

  set(key: string, value: any, microservice: SEPInternalFrontendUrlKeyType) {
    const parseValue = JSON.stringify(value);
    this.setParsed(key, parseValue, microservice);
  }

  setParsed(
    key: string,
    value: string,
    microservice: SEPInternalFrontendUrlKeyType
  ) {
    window[this.storage].setItem(`${microservice}.${key}`, value);
  }

  remove(key: string, microservice: SEPInternalFrontendUrlKeyType) {
    window[this.storage].removeItem(`${microservice}.${key}`);
  }

  clear(microservice: SEPInternalFrontendUrlKeyType) {
    const keys = Object.keys(window[this.storage]);

    keys.forEach((key: string) => {
      if (key?.startsWith(`${microservice}.`)) {
        const keyWithoutMicroService = key.slice(microservice.length + 1);
        this.remove(keyWithoutMicroService, microservice);
      }
    });
  }
}

export interface Storage {
  get: (key: string) => string | null;
  getParsed: <T>(key: string) => T | null;
  set: (key: string, value: any) => void;
  setParsed: (key: string, value: string) => void;
  remove: (key: string) => void;
  clear: () => void;
}
