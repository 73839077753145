import { splitString } from '../array/elements';
import { WEEK_DAY_ABBREVIATION_FORMAT } from './formats';
import { getDateUtilsInternalLibraryInstance } from './internalLibrary/instance';
import { SupportedDateType } from './supportedDateType';

export type UnitOfTime =
  | 'second'
  | 'seconds'
  | 'minute'
  | 'minutes'
  | 'hour'
  | 'hours'
  | 'day'
  | 'days'
  | 'week'
  | 'weeks'
  | 'month'
  | 'months'
  | 'year'
  | 'years';

type IsoWeekUnit = 'isoWeek';

export type StartOfUnits = UnitOfTime | IsoWeekUnit | null;

export class TransformationsDateUtils {
  public static subtractDaysFromDate(
    date: SupportedDateType,
    days: number | string
  ): Date {
    return getDateUtilsInternalLibraryInstance()(date)
      .subtract(+days, 'days')
      .toDate();
  }

  public static addDaysToDate(
    startDate: SupportedDateType,
    days: number | string
  ): Date {
    return getDateUtilsInternalLibraryInstance()(startDate)
      .add(+days, 'days')
      .toDate();
  }

  static addTimeUnitToDate(
    date: SupportedDateType,
    dateUnitToAdd: number,
    dateUnit: UnitOfTime
  ): Date {
    return getDateUtilsInternalLibraryInstance()(date)
      .add(dateUnitToAdd, dateUnit)
      .toDate();
  }

  static subtractTimeUnitToDate(
    date: string | Date,
    dateUnitToSubtract: number,
    dateUnit: UnitOfTime
  ): Date {
    return getDateUtilsInternalLibraryInstance()(date)
      .subtract(dateUnitToSubtract, dateUnit)
      .toDate();
  }

  static startOf(date: SupportedDateType, dateUnit: StartOfUnits): Date {
    return getDateUtilsInternalLibraryInstance()(date)
      .startOf(dateUnit)
      .toDate();
  }

  static endOf(date: SupportedDateType, dateUnit: StartOfUnits): Date {
    return getDateUtilsInternalLibraryInstance()(date).endOf(dateUnit).toDate();
  }

  static dateWeekDay(daysName: string, date: Date, format: string): string {
    const namesArray = splitString(daysName);
    const dayNumber = date.getDay();

    const currentDate =
      getDateUtilsInternalLibraryInstance()(date).format(format);

    return `${currentDate} ${
      dayNumber === 0 ? namesArray[6] : namesArray[dayNumber - 1]
    }.`;
  }

  static weekDayAbbr(date: SupportedDateType): string {
    return getDateUtilsInternalLibraryInstance()(date).format(
      WEEK_DAY_ABBREVIATION_FORMAT
    );
  }
}
